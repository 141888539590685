import { ActiveLink } from "raviger";

import CareIcon, { IconName } from "@/CAREUI/icons/CareIcon";

import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";

import { Avatar } from "@/components/Common/Avatar";

export function NavMain({
  links,
}: {
  links: {
    name: string;
    url: string;
    icon?: string;
    visibility?: boolean;
  }[];
}) {
  return (
    <SidebarGroup>
      <SidebarMenu>
        {links
          .filter((link) => link.visibility !== false)
          .map((link) => (
            <SidebarMenuItem key={link.name}>
              <SidebarMenuButton
                asChild
                tooltip={link.name}
                className={
                  "text-gray-600 transition font-normal hover:bg-gray-200 hover:text-green-700"
                }
                data-cy={`nav-${link.name.toLowerCase().replace(/\s+/g, "-")}`}
              >
                <ActiveLink
                  href={link.url}
                  activeClass="bg-white text-green-700 shadow-sm"
                  exactActiveClass="bg-white text-green-700 shadow-sm"
                >
                  {link.icon ? (
                    <CareIcon icon={link.icon as IconName} />
                  ) : (
                    <Avatar
                      name={link.name}
                      className="size-6 -m-1 rounded-sm"
                    />
                  )}

                  <span className="group-data-[collapsible=icon]:hidden ml-1">
                    {link.name}
                  </span>
                </ActiveLink>
              </SidebarMenuButton>
            </SidebarMenuItem>
          ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
